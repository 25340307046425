@media (max-width: 767px) {
  .statTable h5 {
    font-size: 12px;
  }
  .statTable h1 {
    font-size: 20px;
  }
  .statTable table td {
    width: 0.5rem;
  }
  .feedHeader {
    flex-direction: column;
  }
  .feedHeader h4 {
    text-align: center;
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) {
  .statTable h5 {
    font-size: 14px;
  }
  .statTable h1 {
    font-size: 24px;
  }
  .feedHeader {
    justify-content: space-between;
  }
  .feedHeader h4 {
    text-align: left;
  }
}
.feedBody {
  position: relative;
}
.statTable {
  margin: 15px auto;
  max-width: 500px;
}
.statTable table {
  background-color: #f4f8fb;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}
.sourceMsg {
  font-size: 9px;
  text-align: right;
}
.statTable h5 {
  font-weight: 700;
}
.articles {
  background-color: #eeeeee;
  padding: 15px;
  margin-bottom: 10px;
}
.articles .newsTitle {
  font-size: 16px;
  font-weight: 700;
}
.articles .newsContent {
  padding: 15px 0;
  font-size: 14px;
}
.articles .newImg {
  width: 100%;
  margin-top: 10px;
}
.articles .readMore {
  display: inline-block;
  white-space: nowrap;
}
.paginationChild {
  margin: 16px auto 0;
}
.postButton {
  position: fixed;
  bottom: 15px;
  right: 15px;
}
